<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo" :to="{name:'home'}">
          <logo/>
          <h2 class="brand-text text-primary ml-1">
            USCAS
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          欢迎使用USCAS
        </b-card-title>
        <b-card-text class="mb-2">
          UtopiaXC's股票收集分析系统
        </b-card-text>
        <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login">

          <!-- email -->
          <b-form-group
              label-for="email"
              label="用户名或邮箱">
            <b-form-input
                id="email"
                v-model="userEmail"
                name="login-email"
                @input="checkUsernameOrEmail"
                :state="email_individual"/>
            <b-form-invalid-feedback
                :state="email_individual">
              请输入正确的用户名或邮箱
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">密码</label>
              <b-link :to="{name:'forgetPassword'}">
                <small>忘记密码？</small>
              </b-link>
            </div>
            <b-input-group
                class="input-group-merge">
              <b-form-input
                  id="password"
                  v-model="password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  :state="password_individual"
                  name="login-password"
                  placeholder="密码"
                  @input="checkPassword"/>

              <b-input-group-append is-text>
                <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"/>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
                :state="password_individual">
              请输入正确的密码
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- checkbox -->
          <b-form-group>
            <b-form-checkbox
                id="remember"
                v-model="remember"
                name="checkbox-1">
              记住登录状态
            </b-form-checkbox>
          </b-form-group>

          <!-- submit button -->
          <b-overlay
              :show="login_overlay"
              rounded="sm">
            <b-button
                variant="primary"
                type="submit"
                block
                :disabled="!email_individual||!password_individual">

              登录

            </b-button>
          </b-overlay>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>还没有账号？ </span>
          <b-link :to="{name:'register'}">
            <span>前往注册</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>

import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormInvalidFeedback,
  BOverlay,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Toast from "@core/components/toastification/ToastificationContent";
import user from "@/store/userInfo";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      userEmail: '',
      password: '',
      remember: false,
      login_overlay: false,

      passwordFieldType: 'password',
      email_individual: null,
      password_individual: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    checkUsernameOrEmail() {
      let patternUserName = /^[A-Za-z0-9^%&',;=?$\x22]{2,16}$/
      let patternEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      this.email_individual = patternUserName.test(this.userEmail) || patternEmail.test(this.userEmail);
    },
    checkPassword() {
      this.password_individual = !(this.password === "");
    },
    login() {
      this.login_overlay = true
      this.$http.post('/api/system/user/login', {
        "email": this.userEmail,
        "password": this.$md5(this.password),
        "remember": this.remember
      }).then(response => {
        this.login_overlay = false
        if (response.data.code !== 200) {
          if (response.data.code === 400002)
            this.email_individual = false
          this.password_individual = false
          if (response.data.code === 400005)
            this.password_individual = false
          this.showToast("danger", "错误", "AlertCircleIcon", response.data.message, "top-center", 5000)
        } else {
          this.showToast("success", "成功", "AlertCircleIcon", "登录成功", "top-center", 2000)
          user.userInfo=response.data.data
          let redirect = this.$route.params.redirect
          if (redirect) {
            this.$router.push({name: redirect})
          }
          else
            this.$router.push({name:'home'})
        }
      }).catch(error => {
        this.showToast("danger", "抱歉", "AlertCircleIcon", "服务器错误，" + error, "top-center", 5000)
        this.login_overlay = false
      })
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth';
</style>
